<template>
  <div ref="wrapper">

    <b-sidebar
        v-model="showSidebar"
        right
        :backdrop="false"
        backdrop-variant="dark"
        bg-variant="white"
        shadow
        no-header
    >
      <template #default="{ hide }">
        <header class="b-sidebar-header">
          <button @click="showSidebar = false" type="button" class="pr-2 close">
            <i class="fa fa-close"></i>
          </button>
          <div class="p-3 title text-center border-bottom">
            {{ $t('workflowElements.mysql.databaseTables') }}
          </div>
        </header>
        <div v-if="field && field.data && field.data.tables">
          <div
              v-for="(table, key) in field.data.tables"
              :key="key"
          >
            <div class="accordion accordion-toggle-arrow">
              <div class="card border-right-0 border-left-0 border-top-0">
                <div class="card-header bg-white">
                  <div v-b-toggle="'table_columns_' + key" class="card-title">
                    {{ table.name }}
                  </div>
                </div>
                <b-collapse :id="'table_columns_' + key" accordion="element-accordion-columns">
                  <div class="card-body pt-0">
                    <div
                        v-for="(columns, i) in table.columns"
                        :key="i"
                    >
                      <div class="py-1">
                        <span class="ml-1">{{ columns.name }}</span>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="p-3">{{ $t('workflowElements.mysql.connectionTestToViewTables') }}</h1>
        </div>
      </template>
    </b-sidebar>

    <b-button class="mb-4" variant="primary" @click="showSidebar = !showSidebar">
      {{ $t('workflowElements.mysql.showDatabaseTables') }}
    </b-button>
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <b-alert variant="danger" show>
          {{ $t('workflowElements.mysql.disclaimer') }}
        </b-alert>
      </div>
    </div>

    <div v-if="field && field.twig" class="card card-custom mb-2">
      <div class="card-header">
        <div class="card-title d-flex align-items-center">
          <h3 class="card-label font-weight-bolder text-dark">
            {{ $t('workflowElements.mysql.parsedTwig') }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <pre>
          {{ field.twig }}
        </pre>
      </div>
    </div>

    <div class="d-flex flex-nowrap">
      <div class="mb-4" style="flex-grow: 1; max-width: 100%">
        <div>
          <textarea ref="codeMirror" class="txt"/>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/neo.css";
import "codemirror/theme/panda-syntax.css";
import "codemirror/mode/sql/sql.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/display/autorefresh.js";

export default {
  props: {
    field: {
      type: Object
    },
    node: {
      type: Object
    },
    configValues: {
      type: Array
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSidebar: false,
      edited: false,
      value: "",
      tabSize: 4,
      sqlError: true,
      editor: null,
      queryData: null
    };
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;
      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.setEditor();
  },
  methods: {
    toggleExpand(item) {
      item.isExpand = !item.isExpand;
    },
    showCodeFullscreen() {
      this.$refs["codeFullScreen"].show();
    },
    setEditor() {
      if (this.editor !== null) {
        return false;
      }
      this.editor = CodeMirror.fromTextArea(this.$refs.codeMirror, {
        autoRefresh: true,
        tabSize: 4,
        viewportMargin: 10,
        mode: "text/x-mysql",
        theme: "panda-syntax",
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        hintOptions: {
          completeSingle: false,
          hint: this.hint
        },
        extraKeys: {
          "Ctrl-Space": editor => {
            editor.showHint();
          }
        }
      });
      this.editor.on("keypress", editor => {
        editor.showHint();
      });
      this.editor.on("keyup", () => {
        this.value = this.editor.getValue();
      });
      this.editor.setSize(null, 500);
      this.editor.setValue(this.field.value);
    }
  }
};
</script>

<style scoped>
.CodeMirror.cm-s-panda-syntax.CodeMirror-wrap {
  height: 100% !important;
}

.sql {
  color: blue;
}

.sql:after {
  content: "sql";
  padding-left: 20px;
  font-style: italic;
  float: right;
  color: gray;
}

.sql-table {
  color: blueviolet;
}

.sql-table:after {
  content: "table";
  padding-left: 20px;
  font-style: italic;
  float: right;
  color: gray;
}

.sql-column {
  color: brown;
}

.sql-column:after {
  content: "column";
  padding-left: 20px;
  font-style: italic;
  float: right;
  color: gray;
}
</style>
